import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Playground, store } from "@apollographql/graphql-playground-react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";

const {
  REACT_APP_AUTH_ISSUER_BASE_URL = "",
  REACT_APP_AUTH_CLIENT_ID = "",
  REACT_APP_AUTH_API_IDENTIFIER = "",
} = process.env;

function App() {
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    getAccessTokenSilently,
    user,
    logout,
  } = useAuth0();
  const [headers, setHeaders] = useState();

  useEffect(() => {
    let isMounted = true;
    if (isAuthenticated) {
      getAccessTokenSilently().then((token) => {
        if (isMounted) {
          setHeaders((prev) => ({
            ...(prev || {}),
            Authorization: `Bearer ${token}`,
          }));
        }
      });
    }
    return () => {
      isMounted = false;
    };
  }, [isAuthenticated, getAccessTokenSilently]);

  const handleLogout = () => {
    logout();
    setHeaders({});
  };

  const handleLogin = () => loginWithRedirect();

  const urlSearchParams = new URLSearchParams(window.location.search);

  return (
    <>
      <Provider store={store}>
        <Playground
          endpoint={
            urlSearchParams.get("endpoint") || `${window.location.origin}/api`
          }
          headers={headers}
        />
      </Provider>
      {!isLoading && (
        <div className="auth-container">
          {isAuthenticated ? (
            <>
              <button className="auth-button" onClick={handleLogout}>
                LogOut
              </button>
              <img
                className="auth-avatar"
                alt={user.name}
                src={
                  user?.picture ||
                  "https://www.clipartmax.com/png/middle/248-2487966_matthew-man-avatar-icon-png.png"
                }
                onError={function () {
                  this.src =
                    "https://www.clipartmax.com/png/middle/248-2487966_matthew-man-avatar-icon-png.png";
                }}
              />
            </>
          ) : (
            <button className="auth-button" onClick={handleLogin}>
              LogIn
            </button>
          )}
        </div>
      )}
    </>
  );
}

ReactDOM.render(
  <Auth0Provider
    domain={REACT_APP_AUTH_ISSUER_BASE_URL}
    audience={REACT_APP_AUTH_API_IDENTIFIER}
    clientId={REACT_APP_AUTH_CLIENT_ID}
    redirectUri={window.location.origin}
    useRefreshTokens={true}
    cacheLocation="localstorage"
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);
